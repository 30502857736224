<template>
    <div class="error" v-if="error !== ''">{{error}}</div>
    <div class="wrapper" :style="rows">
        <div class="playernames row">
            <div class="label header" v-html="'Spieler'"></div>
            <div class="player-name label header" v-for="(player, i ) in cluedo.players" :key="i">{{player}}</div>
        </div>
        <div class="persons">
            <div class="label header">Personen</div>
            <div class="person row" v-for="(pers, i) in cluedo.persons" :key="i">
                <div class="person-name label">{{pers.name}}</div>
                <div class="pers-input" v-for="(inp, j) in cluedo.persons[i].inputs" :key="j">
                    <input type="text" class="input-field" v-model="cluedo.persons[i].inputs[j]">
                </div>
            </div>
        </div>
        <div class="weapons">
            <div class="label header">Waffen</div>
            <div class="weapon row" v-for="(w, i) in cluedo.weapons" :key="i">
                <div class="weapon-name label">{{w.name}}</div>
                <div class="weapon-input" v-for="(inp, j) in w.inputs" :key="j">
                    <input type="text" class="input-field" v-model="cluedo.weapons[i].inputs[j]">
                </div>
            </div>
        </div>
        <div class="places">
            <div class="label header">Orte</div>
            <div class="place row" v-for="(pl, i) in cluedo.places" :key="i">
                <div class="place-name label">{{pl.name}}</div>
                <div class="place-input" v-for="(inp, j) in pl.inputs" :key="j">
                    <input type="text" class="input-field" v-model="cluedo.places[i].inputs[j]">
                </div>
            </div>
        </div>
        <div class="new-game link" @click="cluedo.reset()"> Neues Spiel </div>

    </div>
    
</template>

<script lang="ts">
import { Cluedo } from '@/store/cluedo'
import { computed, defineComponent, PropType, reactive, watch } from 'vue'

export default defineComponent({
    props: {
        playernames: {
            type: Array as PropType<string[]>,
            required: false,
        },
        cluedo_name: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        if (props.playernames === undefined || props.cluedo_name === undefined) {
            console.log("creating from existing")
            const tmpCluedo = Cluedo.createFromExisting()
            
            if (tmpCluedo !== undefined) {
                const cluedo = reactive(tmpCluedo)
                const rows = computed(() => {
                    return {
                        "--rows": `${cluedo?.players.length}`
                    }
                })
                watch(cluedo, () => {
                    console.log("in watcher")
                    console.log(cluedo)
                    cluedo.saveToLocalStorage()
                })
                return {
                    cluedo,
                    rows,
                    error: ""
                }

            }
            return{
                error: "fuuck something went wrong :P"
            }
            
        

        }
        console.log(props.playernames)
        const tmpCluedo = new Cluedo(props.playernames, props.cluedo_name)
        const cluedo = reactive(tmpCluedo)
        
        watch(cluedo, () => {
            console.log("in watcher")
            console.log(cluedo)
            cluedo.saveToLocalStorage()
        })
        const rows = computed(() => {
            return {
                "--rows": `${props.playernames?.length}`
            }
        })
        cluedo.saveToLocalStorage()
        return {
            cluedo,
            rows,
            error: ""
        }
    },
})
</script>

<style scoped>
    .row {
        display: grid;
        grid-template-columns: 120px repeat(var(--rows), 2fr);
    }
    .input-field{
        width: 100%;
    }

    .label{
        text-align: left;
    }
    .header{
        font-weight: bold;
        padding: 0.5em 0;
    }
    .new-game{
        height: 2em;
        line-height: 2em;
        background: lightgreen;
        margin: auto
    }
</style>
