import cluedos from "./cluedos.json"

class InputRowEntry{
    name: string;
    inputs: string[];
    constructor(name: string, inputs: string[]) {
        this.name = name
        this.inputs = inputs
    }
}

export class Cluedo {
    name: string;
    players: string[]
    persons: InputRowEntry[];
    weapons: InputRowEntry[];
    places: InputRowEntry[];

    constructor(players: string[], cluedo_name: string, data?: {
        persons: InputRowEntry[];
        weapons: InputRowEntry[];
        places: InputRowEntry[];
    }) {
        const curCluedoInfos = cluedos.filter(c => c.name === cluedo_name)[0]
        const emptyInputRow: string[] = []
        players.forEach(() => {
            emptyInputRow.push("")
        })
        this.name = cluedo_name
        this.players = players
        if(data !== undefined) {
            this.persons = data.persons
            this.places = data.places
            this.weapons = data.weapons
        } else {
            this.persons = []
            this.weapons = []
            this.places = []
            curCluedoInfos?.persons.forEach(pers => {
                this.persons.push(new InputRowEntry(pers,[...emptyInputRow]))
            })
            curCluedoInfos?.weapons.forEach(w => {
                this.weapons.push(new InputRowEntry(w, [...emptyInputRow]))
            })
            curCluedoInfos?.places.forEach(place => {
                this.places.push(new InputRowEntry(place, [...emptyInputRow]))
            })

        }
    }
    static createFromExisting(): Cluedo | undefined {
        const getJsonString = localStorage.getItem("pk-last-cluedo-infos")
        console.log(getJsonString)
        if (getJsonString !== null) {
            const rawCluedo: {
                name: string
                players: string[]
                persons: InputRowEntry[];
                weapons: InputRowEntry[];
                places: InputRowEntry[];
            } = JSON.parse(getJsonString)
            return new Cluedo(rawCluedo.players, rawCluedo.name,rawCluedo)
        }
    }
    saveToLocalStorage() {
        console.log("hello")
        const storageString = JSON.stringify(this)
        console.log("here")
        console.log( storageString)
        console.log("was here")
        localStorage.setItem("pk-last-cluedo-infos",JSON.stringify(this))
    }
    reset() {
        if (confirm("neues spiel starten?")) {
            this.persons.forEach((pers, i) => {
            pers.inputs.forEach((inp, j) => {
                    this.persons[i].inputs[j] = ""
                })
            })
            this.weapons.forEach((w, i) => {
                w.inputs.forEach((inp, j) => {
                    this.weapons[i].inputs[j] = ""
                })
            })
            this.places.forEach((pl, i) => {
                pl.inputs.forEach((inp, j) => {
                    this.places[i].inputs[j] = ""
                })
            })

        }
        
    }
}