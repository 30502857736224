
import { Cluedo } from '@/store/cluedo'
import { computed, defineComponent, PropType, reactive, watch } from 'vue'

export default defineComponent({
    props: {
        playernames: {
            type: Array as PropType<string[]>,
            required: false,
        },
        cluedo_name: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        if (props.playernames === undefined || props.cluedo_name === undefined) {
            console.log("creating from existing")
            const tmpCluedo = Cluedo.createFromExisting()
            
            if (tmpCluedo !== undefined) {
                const cluedo = reactive(tmpCluedo)
                const rows = computed(() => {
                    return {
                        "--rows": `${cluedo?.players.length}`
                    }
                })
                watch(cluedo, () => {
                    console.log("in watcher")
                    console.log(cluedo)
                    cluedo.saveToLocalStorage()
                })
                return {
                    cluedo,
                    rows,
                    error: ""
                }

            }
            return{
                error: "fuuck something went wrong :P"
            }
            
        

        }
        console.log(props.playernames)
        const tmpCluedo = new Cluedo(props.playernames, props.cluedo_name)
        const cluedo = reactive(tmpCluedo)
        
        watch(cluedo, () => {
            console.log("in watcher")
            console.log(cluedo)
            cluedo.saveToLocalStorage()
        })
        const rows = computed(() => {
            return {
                "--rows": `${props.playernames?.length}`
            }
        })
        cluedo.saveToLocalStorage()
        return {
            cluedo,
            rows,
            error: ""
        }
    },
})
